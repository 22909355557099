import { cn } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';
import { MarketingSectionType } from 'src/components/marketing/@types';
import { Button } from 'src/components/shad-base/button';
import useDevice from 'src/hooks/useDevice';
import { Dialog, DialogContent } from '../shad-base/dialog';
import HubspotContactForm from './HubspotContactForm';

const MAX_LAPS = null;
const STOP_CURSOR = false;

export default function MarketingSection({
  title: originalTitle,
  subtitle,
  titlePrefix,
  titlePostfixes,
  isDynamicTitle = false,
  type = 'primary',
  callToAction,
  secondaryCallToAction,
  centerSecondaryTitle,
  children
}: MarketingSectionType) {
  const { isMobile } = useDevice();

  const [postfixTitle, setPostfixTitle] = useState(
    titlePostfixes?.[0] || null
  );
  const titlePostfixIdx = useRef(0);
  const titlePostfixCharIdx = useRef(
    titlePostfixes?.[0]?.length - 1 || 0
  );
  const direction = useRef(-1);
  const delay = useRef(500);
  const cursorCountdown = useRef(10);
  const laps = useRef(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const incrementIdxAndRerender = () => {
    if (
      cursorCountdown.current === 0 &&
      (MAX_LAPS === null || laps.current === MAX_LAPS - 1)
    ) {
      // If we're at the end of the postfixTitle, reset the postfixTitle and start over
      const activeTitlePostfix =
        titlePostfixes?.[titlePostfixIdx.current];

      titlePostfixCharIdx.current =
        (titlePostfixCharIdx.current + direction.current) %
        activeTitlePostfix.length;

      if (titlePostfixCharIdx.current === 0) {
        if (direction.current === -1) {
          titlePostfixIdx.current =
            (titlePostfixIdx.current + 1) % titlePostfixes?.length;
          direction.current = 1;
        }
      } else if (
        titlePostfixCharIdx.current ===
        activeTitlePostfix.length - 1
      ) {
        delay.current = 500;
        cursorCountdown.current = 7; // set to odd number

        direction.current = -1;
      } else {
        delay.current = 40;
      }
    } else {
      cursorCountdown.current--;
    }

    const postFix = titlePostfixes?.[titlePostfixIdx.current].slice(
      0,
      titlePostfixCharIdx.current + 1
    );

    clearTimeout(timerRef.current);
    if (titlePrefix) {
      setPostfixTitle(
        postFix.replace(' ', '\u00a0') +
          (cursorCountdown.current % 2 === 0
            ? isMobile
              ? ''
              : '|'
            : '\u00a0')
      );
    }
    if (
      MAX_LAPS !== null &&
      laps.current > MAX_LAPS - 1 &&
      cursorCountdown.current === -1 &&
      STOP_CURSOR
    ) {
      clearTimeout(timerRef.current);
      return;
    }
    timerRef.current = setTimeout(
      incrementIdxAndRerender,
      delay.current
    );

    const lastChar =
      titlePostfixes?.[titlePostfixes?.length - 1].charAt(-1);
    if (
      postFix + lastChar ===
        titlePostfixes?.[titlePostfixes?.length - 1] &&
      cursorCountdown.current === 0
    ) {
      laps.current++;
    }
  };

  useEffect(() => {
    if (isDynamicTitle) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!titlePostfixes) {
        return;
      }
      timerRef.current = setTimeout(incrementIdxAndRerender, 500);
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const primaryComponent = (
    <div
      className={cn(
        'flex w-full max-w-full justify-center rounded-3xl px-lg py-lg lg:flex-row lg:px-xl'
      )}
    >
      <div
        className={cn(
          'flex h-full w-full max-w-screen-xl flex-col sm:flex-row'
        )}
      >
        {/* Title */}
        <div className="flex flex-col">
          <div className="mb-xl w-full flex-col">
            {isDynamicTitle ? (
              <div
                className={
                  'flex w-full flex-col items-center  lg:max-w-screen-xl lg:flex-row lg:items-baseline lg:justify-start'
                }
              >
                <p
                  className={
                    'whitespace-nowrap text-2xl font-bold sm:text-4xl lg:flex-nowrap lg:text-6xl'
                  }
                >
                  {originalTitle || titlePrefix}&nbsp;
                </p>
                <p
                  className={
                    'inline-block flex-nowrap whitespace-nowrap bg-gradient-to-r from-blue-600 to-primary bg-clip-text pb-xl text-2xl font-bold text-transparent sm:text-4xl lg:text-6xl'
                  }
                >
                  {postfixTitle}
                </p>
              </div>
            ) : (
              <div className="flex flex-col">
                <h1 className="mb-lg font-sans text-4xl font-black sm:mr-lg sm:text-4xl md:text-6xl lg:text-7xl">
                  {originalTitle}
                </h1>
                <div className="max-w-5xl text-lg md:text-2xl lg:text-2xl">
                  {subtitle}
                </div>
              </div>
            )}
          </div>
          {/* Calls to Action */}
          {callToAction && (
            <div className="mb-xl flex w-full flex-row ">
              <div className="flex w-full flex-col md:flex-row">
                <div className="flex flex-row flex-nowrap">
                  <Button
                    size="lg"
                    className="hover:bg-blue-800"
                    onClick={() => callToAction.onClick()}
                  >
                    {callToAction.text}
                  </Button>
                </div>
                {secondaryCallToAction && (
                  <div className="mt-md flex flex-row flex-nowrap md:ml-md md:mt-0">
                    <Button
                      id="button"
                      variant="outline"
                      size="lg"
                      onClick={() => setIsOpen(true)}
                    >
                      {secondaryCallToAction.text}
                    </Button>
                    <Dialog open={isOpen} onOpenChange={setIsOpen}>
                      <DialogContent>
                        <HubspotContactForm
                          region="na1"
                          portalId="46198686"
                          formId="f9f357db-b6c7-4d26-bd47-da4f375683a7"
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Children */}
        {children && <div>{children}</div>}
      </div>
    </div>
  );
  const secondaryComponent = (
    <div
      className={cn(
        'flex w-full justify-center rounded-3xl px-lg py-xl lg:flex-row lg:px-xl',
        type === 'primary' && 'max-w-full  md:bg-card',
        type === 'secondary' ? ' max-w-screen-2xl' : ''
      )}
    >
      <div
        className={cn(
          'w-full max-w-screen-xl',
          type === 'primary' ? 'flex h-full flex-col' : ''
        )}
      >
        {/* Title */}
        <div className="mb-xl flex w-full flex-row justify-center">
          {type === 'primary' ? (
            isDynamicTitle ? (
              <div
                className={
                  'flex w-full flex-col items-center justify-center lg:max-w-screen-xl lg:flex-row lg:items-baseline lg:justify-start'
                }
              >
                <p
                  className={
                    'whitespace-nowrap text-2xl font-bold sm:text-4xl lg:flex-nowrap lg:text-6xl'
                  }
                >
                  {originalTitle || titlePrefix}&nbsp;
                </p>
                <p
                  className={
                    'inline-block flex-nowrap whitespace-nowrap bg-gradient-to-r from-blue-600 to-primary bg-clip-text pb-xl text-2xl font-bold text-transparent sm:text-4xl lg:text-6xl'
                  }
                >
                  {postfixTitle}
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center text-center">
                <h1 className="mb-4 font-sans text-4xl font-black sm:text-4xl md:text-6xl lg:text-7xl">
                  {originalTitle}
                </h1>
                <div className="max-w-5xl text-lg md:text-2xl lg:text-2xl">
                  {subtitle}
                </div>
              </div>
            )
          ) : (
            <div
              className={cn(
                'flex w-full flex-row',
                centerSecondaryTitle && 'justify-center text-center'
              )}
            >
              <p className="max-w-5xl text-xl font-black md:text-4xl lg:max-w-3xl lg:text-6xl">
                {originalTitle}
              </p>
            </div>
          )}
        </div>
        {/* Calls to Action */}
        {callToAction && (
          <div className="mb-xl flex w-full flex-row justify-center">
            <div className="flex w-full flex-col items-center md:flex-row md:justify-center">
              <div className="flex flex-row flex-nowrap">
                <Button
                  size="lg"
                  className="hover:bg-blue-800"
                  onClick={() => callToAction.onClick()}
                >
                  {callToAction.text}
                </Button>
              </div>
              {secondaryCallToAction && (
                <div className="mt-md flex flex-row flex-nowrap md:ml-md md:mt-0">
                  <Button
                    id="button"
                    variant="outline"
                    size="lg"
                    onClick={() => setIsOpen(true)}
                  >
                    {secondaryCallToAction.text}
                  </Button>
                  <Dialog open={isOpen} onOpenChange={setIsOpen}>
                    <DialogContent>
                      <HubspotContactForm
                        region="na1"
                        portalId="46198686"
                        formId="f9f357db-b6c7-4d26-bd47-da4f375683a7"
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              )}
            </div>
          </div>
        )}
        {/* Children */}
        {children && (
          <div
            className={cn(
              'flex flex-col',
              type === 'primary' && 'grow',
              'w-full items-center md:justify-center'
            )}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
  return type == 'primary' ? (
    <>{primaryComponent}</>
  ) : (
    <>{secondaryComponent}</>
  );
}
export function MarketingContainer({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex w-full flex-col items-center pb-2xl">
      <div className="w-full max-w-screen-2xl">{children}</div>
    </div>
  );
}
