import { MarketingCardType } from 'src/components/marketing/@types';
import MarketingCard from 'src/components/marketing/MarketingCard';
import { getListItemKey } from 'src/utils/format';

export default function FlexCardLayout({
  rows,
  spacing = 'lg'
}: {
  rows: {
    cards: MarketingCardType[];
  }[];
  spacing?: 'sm' | 'md' | 'lg';
}) {
  return (
    <div className="flex flex-col flex-nowrap w-full">
      {rows.map((row, idx) => {
        return (
          <div
            key={getListItemKey(idx)}
            className={
              'flex flex-col md:flex-row ' + (idx > 0 ? `mt-${spacing}` : '')
            }
          >
            {row.cards.length === 1 && (
              <div className="min-h-[510px] w-full">
                <MarketingCard {...row.cards[0]} />
              </div>
            )}
            {row.cards.length === 2 && (
              <>
                <div
                  className={`w-full min-h-[510px] md:w-1/2 mb-${spacing} md:mb-0 `}
                >
                  <MarketingCard {...row.cards[0]} />
                </div>
                <div
                  className={`w-full min-h-[510px] md:w-1/2 md:ml-${spacing}`}
                >
                  <MarketingCard {...row.cards[1]} />
                </div>
              </>
            )}
            {row.cards.length === 3 && (
              <>
                <div className="w-full md:w-1/3 md:mr-lg col-auto h-full min-h-[510px]">
                  <div className="flex-col flex h-full">
                    <div className="mb-lg h-80">
                      <MarketingCard {...row.cards[0]} />
                    </div>
                    <div className="mb-lg md:mb-0 h-80">
                      <MarketingCard {...row.cards[1]} />
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-2/3 min-h-full">
                  <MarketingCard {...row.cards[2]} />
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
    // <div className="flex flex-col flex-nowrap">
    //   <div>
    //     <MarketingCard
    //       title="Card with an image and badges and a call to action."
    //       badges={[{ label: 'Badge 1' }, { label: 'Badge 2' }]}
    //       imageUrl="/static/landingpage/green-building.jpg"
    //       callToAction={{ text: 'Call to action', onClick: () => null }}
    //     />
    //   </div>
    //   <div className="mt-lg">
    //     <div className="flex flex-col md:flex-row">
    //       <MarketingCard
    //         title="Card with no image and badges and a call to action."
    //         badges={[{ label: 'Badge 1' }, { label: 'Badge 2' }]}
    //         callToAction={{ text: 'Call to action', onClick: () => null }}
    //       />
    //       <div className="mt-lg md:mt-0 md:ml-lg">
    //         <MarketingCard
    //           title="Card with no image and no badges and a call to action."
    //           callToAction={{ text: 'Call to action', onClick: () => null }}
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <div className="flex flex-col md:flex-row mt-lg w-full">
    //     <div className="w-full md:w-1/3 md:mr-lg h-full">
    //       <div className="flex-col flex">
    //         <div className="mb-lg">
    //           <MarketingCard
    //             subtitle={`Mini Marketing Card`}
    //             description={'This could be an image too.'}
    //           />
    //         </div>
    //         <div className="mb-md md:mb-0">
    //           <MarketingCard
    //             subtitle="Another Mini Marketing Card"
    //             badges={[
    //               {
    //                 label: 'Badge 1'
    //               }
    //             ]}
    //             description="maybe this one is a little larger than the first one."
    //           />
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-full md:w-2/3 ">
    //       <MarketingCard
    //         title="Big Card with Title"
    //         badges={[
    //           {
    //             label: 'Badge 1'
    //           },
    //           {
    //             label: 'Badge 2'
    //           }
    //         ]}
    //         description="This is a description for a larger card, where there is a title."
    //         subtitle="Subtitle for the big card."
    //         callToAction={{ text: 'Call to action', onClick: () => null }}
    //       />
    //     </div>
    //   </div>
    //   <div className="mt-lg">
    //     <MarketingCard title="Marketing card with just title." />
    //   </div>
    // </div>
  );
}
